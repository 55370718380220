import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-direction: column;
  max-width: 350px;
  width: 350px;
  max-height: 505px;
  font-family: 'Prompt', sans-serif;
  font-weight: bold;
  font-size: 35px;
  color: ${(props) => props.theme.darkBackground};
  margin: 0 35px 35px 35px;
  .site-block {
    position: relative;
    width: 100%;
    background: ${(props) => props.theme.greyDisabled};
    height: 400px;
    font-family: 'Prompt', sans-serif;
    font-weight: 600;
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    &:hover {
      cursor: pointer;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
    > div {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      font-size: 40px;
      &:hover {
        cursor: pointer;
        background: rgba(0, 0, 0, 0.4);
      }
    }
  }
  .site-title-text {
    width: 100%;
    font-family: 'Prompt', sans-serif;
    font-weight: bold;
    font-size: 24px;
    margin-top: 14.5px;
    color: white;
    &:hover {
      cursor: pointer;
    }
  }
  .cam-status-badge {
    width: 75px;
    height: 30px;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
    margin-top: 14.5px;
    .cam-status-text {
      font-family: 'Prompt-Medium', sans-serif;
      font-size: 10px;
      line-height: 1.25;
      color: ${(props) => props.theme.onlineCamera};
    }
  }
`
