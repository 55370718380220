import React from 'react'
import PropTypes from 'prop-types'
import SkeletonLoading from '../SkeletonLoading/Loadable'
import SiteCardStyled from './styledComponent'
import { makeFirstLetterCapital } from '../../utils'

class SiteCard extends React.PureComponent {
  renderCameraStatus() {
    const { siteObject } = this.props
    let output = (
      <div className="cam-status-badge">
        <div className="cam-status-text text-style">{`${siteObject.active_camera}/${siteObject.all_camera} ACTIVE`}</div>
      </div>
    )
    return output
  }

  getContent() {
    const abbreviateSiteName = this.props.siteObject.name.substring(0, 2).toUpperCase()
    let output = <SkeletonLoading />
    if (this.props.siteObject) {
      output = (
        <>
          <div className="site-block" onClick={() => this.props.onSiteClick(this.props.siteObject.site_id)}>
            <img src={this.props.siteObject.pre_encode_image} alt="" />
            <div className="text-style">{abbreviateSiteName}</div>
          </div>
          <div className="site-title-text prevent-text-overflow" onClick={() => this.props.onSiteClick(this.props.siteObject.site_id)}>
            {makeFirstLetterCapital(this.props.siteObject?.name)}
          </div>
          {this.renderCameraStatus()}
        </>
      )
    }
    return output
  }

  render() {
    return <SiteCardStyled className="site-card">{this.getContent()}</SiteCardStyled>
  }
}

SiteCard.propTypes = {
  siteObject: PropTypes.shape({
    name: PropTypes.string,
    project_id: PropTypes.number,
    site_id: PropTypes.number,
    all_camera: PropTypes.number,
    active_camera: PropTypes.number
  }).isRequired,
  onSiteClick: PropTypes.func.isRequired
}

export default SiteCard
